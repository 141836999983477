import { BankConfig } from '../typings/types';

const config: BankConfig = {
  name: 'Kreditbanken',
  bankId: 15,
  logoWidth: 220,
  color: '#00a370',
  googleAnalytics: {
    test: 'UA-53256967-20',
    production: 'UA-53256967-19'
  },
  forceOverride: false,
  backgroundGradient: ['#f5f5f5', '#f5f5f5'],
  backgroundImage: '15_bg.png',
  topBar: { background: 'white', color: '#424242' },
  body: { headlineColor: '#424242', bodyColor: '#424242' }
};

export default config;
