import { mergeObjects } from '../app/common/utils';
import StandardForm from './form.standard';
import { FormConfig } from '../typings/types';

const customizations: FormConfig = {
  ...StandardForm,
  form: {
    ...StandardForm.form,
    questions: {
      assetsAmount: {
        label:
          'Hvor stor er din samlede formue placeret i kontanter, indestående på konti og værdipapirer? (pensionsopsparing medregnes ikke)'
      }
    }
  }
};

export default mergeObjects(StandardForm, customizations);
